import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '@services';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  isAdmin:boolean=false;
  constructor(private localStorageService: LocalStorageService) {
    if(this.localStorageService.getUserInfo()){
      this.isAdmin=(this.localStorageService.getUserInfo().role === 'admin');
    }
    //console.log("isAdmin >> ", this.localStorageService.getUserInfo());
    
   }

  ngOnInit() {
  }

}
